<template>
  <base-section id="aboutourproduct">
    <base-section-heading color="grey lighten-2" title="Servicios" />

    <v-container>
      <v-row>
        <v-col v-for="card in cards" :key="card.title" cols="12" md="4">
          <base-info-card v-bind="card" />

          <v-img contain width="300px" :src="require(`@/assets/${card.img}`)" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionAboutOurProduct",
  data: () => ({
    cards: [
      {
        title: "Instalación de un aire acondicionado",
        img: "climatizacion1.jpg",
        // subtitle: 'Subtitulo',
        // text:
        // "En reparaciones e instalaciones para todas las marcas y modelos de lavadoras, hornos, Campanas extractoras, termos eléctricos, neveras secadoras y lavavajillas.",
        callout: "1"
      },
      {
        title: " Sistema de calefacción eficiente",
        img: "climatizacion2.jpg",
        // subtitle: 'Subtitulo',
        // text: 'Son una excelente opción para agregar otro plano de diseño a tus proyectos.',
        callout: "2"
      },
      {
        title: " Control inteligente de temperatura",
        img: "climatizacion3.jpg",
        // subtitle: 'Subtitulo',
        // text: 'Es un material de construcción utilizado para la ejecución de tabiques interiores y revestimientos de techos y paredes. Suele utilizarse en forma de placas, paneles o tableros industrializados.',
        callout: "3"
      },
      {
        title: "Exterior de la instalación",
        img: "climatizacion4.jpg",
        // subtitle: 'Subtitulo',
        // text: 'Son sustancias o compuestos químicos que tienen como objetivo detener el agua, impidiendo su paso, y son muy utilizados en el revestimiento de piezas y objetos que deben ser mantenidos secos. ',
        callout: "4"
      }
      // {
      //   title: 'Reparación de hornos',
      //   // subtitle: 'Subtitulo',
      //   // text: 'Para la instalación, lo primero que debe ejecutarse es la medición del área o espacio, para obtener las medidas de la puerta a adquirir. Luego de esto se da inicio al trabajo en sí.',
      //   callout: '4',
      // },
      // {
      //   title: 'Reparación de secadoras',
      //   // subtitle: 'Subtitulo',
      //   // text: 'Con el paso del tiempo y el uso, las correas de las persianas se deterioran y pierden eficacia. Cuando empiezan a deshilacharse y debilitarse, es aconsejable sustituirlas para evitar roturas imprevistas y asegurarse así un correcto funcionamiento',
      //   callout: '5',
      // },
    ]
  })
};
</script>